// Layout
// ==========================================================================

// Content
// ==========================================================================

// Content wraps the entire site content block
#content {
  @extend %site-width-container;
  @extend %contain-floats;
  padding-bottom: $gutter;

  @include media(desktop) {
    padding-bottom: $gutter*3;
  }
}


// Phase banner
// ==========================================================================

.phase-banner {
  @include phase-banner(alpha);
}


// Grid layout
// ==========================================================================

// Usage:
// For two equal columns

// <div class="grid-row">
//   <div class="column-half">
//
//   </div>
//   <div class="column-half">
//
//   </div>
// </div>

// Use .grid-row to define a row for grid columns to sit in
.grid-row {
  @extend %grid-row;
}

// Use .grid-column to create a grid column with 15px gutter
// By default grid columns break to become full width at tablet size
.column-quarter {
  @include grid-column(1/4);
}

.column-half {
  @include grid-column(1/2);
}

.column-third {
  @include grid-column(1/3);
}

.column-two-thirds {
  @include grid-column(2/3);
}
