// Lists
// ==========================================================================

ul,
ol {
  list-style-type: none;
  padding: 0;
}

// Bulleted lists
.list-bullet {
  list-style-type: disc;
  padding-left: 20px;
}

// Numbered lists
.list-number {
  list-style-type: decimal;
  padding-left: 20px;
  
  @include ie-lte(7) {
    padding-left: 28px;
  }
}

.list-bullet,
.list-number {
  margin-top: 5px;
  margin-bottom: 20px;
}

.list-bullet li,
.list-number li {
  margin-bottom: 5px;
}

