// Buttons
// ==========================================================================

.button {
  @include button ($button-colour);
  @include box-sizing (border-box);
  padding: em(10) em(15) em(5) em(15);
  vertical-align: top;
  @include media (mobile) {
    width: 100%;
  }
}

// Fix unwanted button padding in Firefox
.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button:focus {
  outline: 3px solid $focus-colour;
}

// Disabled buttons
.button[disabled="disabled"] {
  background: $button-colour;
}

.button[disabled="disabled"]:focus {
  outline: none;
}

// Start now buttons
.button-start,
.button-get-started {
  @include bold-24;
  background-image: file-url("icons/icon-pointer.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  padding: em(7) em(41) em(4) em(16);

  @include device-pixel-ratio {
    background-image: file-url("icons/icon-pointer-2x.png");
    background-size: 30px 19px;
  }

  @include ie(6) {
    background-image: file-url("icons/icon-pointer-2x.png");
  }
}
