/*  
====================================================
Typography
====================================================
*/

#content {
  @include core-19;
}

h1 {
  @extend .heading-xlarge;
}

h2 {
  @extend .heading-large;
}

h3 {
  @extend .heading-medium;
}

h4 {
  @extend .heading-small;
}
h5 {
  @extend .heading-small;
}

.heading-collapse {
  margin-bottom: 0 !important;
}
.headings-collapse {
  h1, h2, h3, h4, h5 {
    @extend .heading-collapse;

    & + .panel-indent {
      margin-top: .5em;
    }
  }
}


ul {
  @extend .list-bullet;
}
ol {
  @extend .list-number;

  &.list-number-bold {
    li {
      list-style: none;
      counter-increment: item;

      &:before {
        content: counter(item) ".";
        display: inline-block;
        @include bold-19();
        margin-right: .5em;
        margin-left: -1.5em;
        width: 1em;
        text-align: right;
      }
    }

    @include ie-lte(7) {
      padding-left: 2em;

      li {
        list-style-type: decimal;

        &:before {
          display: none;
        }
      }
    }
  }
}

@include ie-lte(7) {
  ul, ol {
    margin-left: 0;
  }
}

address {
  @extend p;
  font-style: normal;
}

summary {
	@include ie-lte(7) {
		margin-left: 0;
	}
}
