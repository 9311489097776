/*
====================================================
Modules

These styles apply to elements linked to JavaScript
modules.
====================================================
*/


/*== JS classes ======================================*/
// It turns out that IE6 can handle dat javascriptz
// So we can override the default from MoJ Template
@include ie(6) {
  .js-hidden {
    display: none;
  }
  .nojs-hidden {
    display: block;
  }
}

.nojs-hidden {
  display: none;
}

.js-enabled .nojs-hidden {
  display: block;
}


/*== Details pseudo-element ===============================*/
.js-Details {
  .details-trigger {
    display: none;
  }
}

.js-enabled .js-Details {

  .details-trigger {
    display: inline-block;
    margin-bottom: 0.263158em;
    text-decoration: none;

    .arrow {
      font-style: normal;
      margin-right: .35em;
    }
    .summary {
      text-decoration: underline;
    }
  }

  .details-content {
    display: none;
  }
}
