// Large hit area
// Radio buttons & checkboxes

// By default, block labels stack vertically
.block-label {

  display: block;
  float: none;
  clear: left;
  position: relative;

  background: $panel-colour;
  border: 1px solid $panel-colour;
  padding: (18px $gutter $gutter-half $gutter*1.8);

  margin-bottom: 10px;
  cursor: pointer; // Encourage clicking on block labels

  @include media(tablet) {
    float: left;
    // width: 25%; - Test : check that text within labels will wrap
  }

  // Absolutely position inputs within label, to allow text to wrap
  input {
    position: absolute;
    top: 15px;
    left: $gutter-half;
    cursor: pointer;
    margin: 0;
    width: 29px;
    height: 29px;
    @include ie(8) {
      top: 12px;
    }
  }

  // Change border on hover
  &:hover {
    border-color: $black;
  }
}

.block-label:last-child {
  margin-bottom: 0;
}

// To stack horizontally, use .inline on parent, to sit block labels next to each other
.inline .block-label {
  clear: none;
  margin-right: 10px;
}

// Selected and focused states

// Add selected state
.js-enabled label.selected {
  background: $white;
  border-color: $black;
}

// Add focus to block labels
.js-enabled label.focused {
  outline: 3px solid $focus-colour;
}

// Remove focus from radio/checkboxes
.js-enabled .focused input:focus {
  outline: none;
}
