// Helpers
// ==========================================================================

// Path to assets for use with file-url() is not already defined
@if ($path == false) {
  $path: "/public/images/";
}

// Return ems from a pixel value
// This assumes a base of 19px
@function em($px, $base: 19) {
  @return ($px / $base) + em;
}

// Want to see how the grid works?
// add this class to the body to see how grid padding is set
.example-highlight-grid {
  .grid-row {
    background: $grey-2;
  }

  .column-highlight {
    background: $grey-3;
    width: 100%;
  }

}

// Hide, but not for screenreaders
.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

// Hide, only when JavaScript is enabled
.js-enabled .js-hidden {
  display: none;
}
