// Panels
// ==========================================================================

// Indented panels with a grey left hand border
.panel-indent {
  @extend %contain-floats;
  clear: both;
  border-left: 5px solid $border-colour;

  padding: em(15,19);
  margin-bottom: em(15,19);

  :first-child {
    margin-top: 0;
  }

  :only-child,
  :last-child {
    margin-bottom: 0;
  }

}

.panel-indent-info {
  border-left-width: 10px;
}

// Indented panels within form groups
.form-group .panel-indent {
  padding-bottom: 0;
}
