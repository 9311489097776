/*
====================================================
Elements
====================================================
*/

/*== Horizontal rule ===============*/
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $grey-2;
    margin: 1em 0 2em;
    padding: 0;
}


/*== phase banner language switcher ===============*/
.language-switcher {
	float: right;
	margin: 0 0 0 1em;
	padding: 0 0 8px;
	@include core-16;

	ul {
		padding: 0;
		margin: 0;
	}
	li {
		margin: 0;
		list-style-type: none;
	}
}

/*== emergency message ========================*/
#emergency_message_box {
    background-color: #fef7f7;
    clear: both;
    border: 3px solid #df3034;
    box-sizing: border-box;
    padding: 20px;
}

/*== content header ========================*/
.content-header {
	margin: 0 0 1.675em;

	h1 {
		margin-bottom: .25em;
	}

	> p:first-of-type {
		@extend .lede;
	}
}


/*== sidebar/related content =================*/
.related {
	margin-top: .75em;
	padding-top: 5px;
	border-top: 10px solid $govuk-blue;

	h2,
	h3 {
		margin-top: .5em;
		margin-bottom: .5em;
	}

	nav {
		ul {
			padding: 0;
		}

		li {
			list-style-type: none;
			margin: .25em 0 1em;
		}
	}
}


/*== indent panel ===================*/
.panel-indent {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 10px;
	margin-bottom: 45px;
	border-left-width: 4px;

	@include media(tablet) {
		padding-left: 15px;
	}
}


/*== nudge element up ==================*/
.move-up {
	margin-top: -1em;
}


/*== grey intro panel =================*/
.panel-grey {
	background: $grey-3;
	padding: 10px;
	margin: 30px 0 45px;

	@include media(tablet) {
		padding: 15px;
	}
}

.panel-grey > :first-child {
	margin-top: 0;
}
.panel-grey > :last-child {
	margin-bottom: 0;
}


/*== Success header ====================*/
.success-header {
	margin: 2em 0;
	padding: 40px 20px;
	background: $turquoise;
	color: $white;
	text-align: center;

	h1 {
		@include bold-36();
		margin: .25em 0;
	}

	p {
		margin: .25em 0;
	}

	.ticked:before {
		content: "✓";
		background: url(../images/icons/tick.png) 0 center no-repeat;
    display: inline-block;
    width: 40px;
    height: 0;
    padding-top: 1em;
    overflow: hidden;
    vertical-align: top;
	}
}


/*== Important paragraph with icon =============*/
p.important {
	padding: 7px 0 0 40px;
	min-height: 27px;
	background: url(../images/icons/icon-important.png) no-repeat top left;
}


/*== Header with grey line to the right =======*/
.header-line {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		width: 100%;
		border-bottom: 2px solid $grey-2;
		bottom: .5em;
	}

	> span {
		position: relative;
		padding-right: 10px;
		background: #fff;
	}
}


/*== Tables =====================================*/
.table-wrap {
	max-width: 100%;
	overflow-x: visible;
	overflow-y: auto;
}

table {
	margin-bottom: 2em;

	th, td {
		@include core-19;
		vertical-align: top;

		&:last-child {
			padding-right: 0;
		}
	}
	th {
		font-weight: 700;
	}

	.no-border {
		td,
		th {
			border-bottom: none;
		}
	}
}
